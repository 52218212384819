<nav class="navbar navbar-expand-lg navbar-dark bg-primary">
	<div class="container-fluid d-flex">
    <div class="d-inline-flex align-items-center justify-content-center position-relative" [class]="!isMenuCollapsed ? ['order-2'] : screenWidth > 980 ? ['order-last'] : ['order-2']">
      <app-wapp-btn class="mx-2"></app-wapp-btn>
      <app-alert-btn class="mx-2"></app-alert-btn>
      <app-user-btn class="mx-2"></app-user-btn>
    </div>

		<!-- Step 3: Toggle the value of the property when the toggler button is clicked. -->
		<button class="navbar-toggler order-0" type="button" (click)="isMenuCollapsed = !isMenuCollapsed">&#9776;</button>
		<!-- Step 2: Add the ngbCollapse directive to the element below. -->
    <div class="d-flex align-items-center justify-content-center order-1">
      <img width="40" alt="Brand Logo" src="/assets/images/rody.jpg" />
      <a class="navbar-brand fs-5 ms-2" routerLink="/" (click)='isMenuCollapsed = true'>Firulais</a>
      <a [hidden]="screenWidth < 661" class="navbar-brand fs-5"
        href="https://wa.me/+5493624683656"
        (click)='isMenuCollapsed = true'
      >
        <i class="fa-brands fa-whatsapp fa-1x"></i> +54 9 (362) 468-3656
      </a>
    </div>
    <div [(ngbCollapse)]="isMenuCollapsed" class="collapse navbar-collapse" [class]="!isMenuCollapsed ? ['order-last'] : (screenWidth > 899 ? ['order-2'] : ['order-last'])">
      <ul ngbNav [activeId]="_activatedRoute.fragment | async" class="navbar-nav">
        <li [hidden]="isMenuCollapsed || ( !isMenuCollapsed && screenWidth ) > 660" class="nav-item text-center">
          <a class="navbar-brand fs-6" href="https://wa.me/+5493624683656">
            <i class="fa-brands fa-whatsapp fa-1x"></i> +54 9 (362) 468-3656
          </a>
        </li>
        <!--li class="fs-6" [ngbNavItem]="menu.link" *ngFor="let menu of usrMenu">
          <a ngbNavLink *ngIf="(menu.link && !menu.fragment)" [hidden]='menu.hidden || false' [routerLink]="menu.link" routerLinkActive="active" [innerHTML]="menu.title" (click)="isMenuCollapsed=true"></a>
          <a ngbNavLink *ngIf="(menu.link && menu.fragment)" [hidden]='menu.hidden || false' [routerLink]="menu.link" [fragment]="menu.fragment" routerLinkActive="active" [innerHTML]="menu.title"></a>
        </li-->
        <li class="fs-6" [ngbNavItem]="menu.link" *ngFor="let menu of userTopMenu">
          <a ngbNavLink *ngIf="(menu.link && !menu.fragment)" [hidden]='menu.hidden || false' [routerLink]="menu.link" routerLinkActive="active" [innerHTML]="texto(menu)" (click)="isMenuCollapsed=true"></a>
          <a ngbNavLink *ngIf="(menu.link && menu.fragment)" [hidden]='menu.hidden || false' [routerLink]="menu.link" [fragment]="menu.fragment" routerLinkActive="active" [innerHTML]="texto(menu)"></a>
        </li>
      </ul>
    </div>
	</div>
</nav>
