<div class="mx-2 position-relative" ngbDropdown #myDrop="ngbDropdown"  style="color: #ededed;">
  <button
    ngbDropdownToggle
    class="btn  btn-outline-light rounded-circle position-relative"
    style="width: 40px; height: 40px;">
    <img src="{{_loggedUser().image}}" alt=""
      class="position-absolute top-50 start-50 translate-middle"
    >
    <span *ngIf="alerts().length" class="position-absolute top-0 start-100 translate-middle bg-danger badge rounded-pill mt-1">
      {{alerts().length}}
      <span class="visually-hidden">unread messages</span>
    </span>
  </button>
  <div class="dropdown-menu pt-0 dropdown-menu-end dropdown-menu-lg-end logform" ngbDropdownMenu aria-labelledby="authdropdownForm">
    <div  *ngIf="isLogged(); then viewLogged; else viewLogin" ></div>
  </div>
  <ng-template #viewLogin>
    <form class="px-3 py-3">
      <div class="mb-3">
        <label for="exampleDropdownFormEmail1">Email address</label>
        <input
          id="exampleDropdownFormEmail1"
          type="email"
          class="form-control"
          placeholder="email@example.com"
          name="username"
          autocomplete="username"
          [(ngModel)]="user.email">
      </div>
      <div class="mb-3">
        <label for="exampleDropdownFormPassword1">Password</label>
        <input
          id="exampleDropdownFormPassword1"
          type="password"
          class="form-control"
          placeholder="Password"
          name="password"
          autocomplete="current-password"
          [(ngModel)]="user.password">
      </div>
      <!--div class="form-check">
        <input type="checkbox" class="form-check-input" id="dropdownCheck">
        <label class="form-check-label" for="dropdownCheck">
          Remember me
        </label>
      </div-->
      <button type="submit" class="btn btn-primary" (click)="$event.stopPropagation();login(myDrop)">Sign in</button>
    </form>
    <div class="dropdown-divider"></div>

    <button type="button" ngbDropdownItem [routerLink]="['users/signup']">Registarse</button>
    <button type="button" ngbDropdownItem [routerLink]="['users/forgot']">Olvidó la clave?</button>
  </ng-template>
  <ng-template #viewLogged>
    <div class="text-bg-info rounded-top p-2 mb-2"><h5 class="p-2">{{_loggedUser().showname}}</h5></div>
    <ng-container *ngIf="!_loggedUser().emailvalidated then  viewValidate">
      <!--div ngbDropdownMenu aria-labelledby="authdropdownForm" class="dropdown-menu dropdown-menu-md-start dropdown-menu-lg-end logform"-->
      <button type="button" ngbDropdownItem (click)="$event.stopPropagation();myDrop.close;profile()">Profile</button>
    </ng-container>
    <div class="dropdown-divider"></div>
    <button type="button" (click)="$event.stopPropagation();myDrop.close;cuenta()" ngbDropdownItem>Cuenta</button>
    <button type="button" (click)="$event.stopPropagation();myDrop.close;profile()" ngbDropdownItem>Perfil</button>
    <button type="button" (click)="$event.stopPropagation();myDrop.close;changePassword()" ngbDropdownItem>Cambiar Clave</button>
    <div class="dropdown-divider"></div>
    <button type="button" (click)="$event.stopPropagation();myDrop.close;logout()" ngbDropdownItem>Logout</button>
  </ng-template>
  <ng-template #viewValidate>
    <button type="button" ngbDropdownItem (click)="$event.stopPropagation();myDrop.close;validateEmail()">Validar e-Mail</button>
  </ng-template>
</div>
